body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-wrapper {
  margin: 200px 0 0 0;
  background: grey;
  padding: 100px;
  border-radius: 26px;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homeContainer {
  padding: 0 0 0 17%;
  margin-top: 70px;
}

.sidebar {
  height: 100%;
  width: 17%;
  position: fixed;
  z-index: 1;
  top: 55px;
  left: 0;
  background-color: #343a40;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar a {
  padding: 10px;
  text-decoration: none;
  font-size: 15px;
  color: #ffffff;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sideBarIcon {
  color: #ffffff;
  font-size: 16px;
}

.container-ship {
  margin: 20px 0;
}

.olvido {
  margin-top: 15px;
}

.titulo {
  color: #ffffff;
}

.meliico {
  margin-right: 20px;
}

.footericon {
  margin-bottom: 5px;
}

.datepicker {
  border-color: blueviolet;
  background: #343a40;
}

.downloadGraphic {
  float: right;
}

.float {
  float: left;
  font-size: 25px;
  font-weight: 600;
  margin-top: 13px;
  margin-left: 15px;
}

.download-sellByProduct {
  margin: 10px;
  float: right;
}

.max{
  max-height: 483px;
  overflow-x: scroll;
}

.table {
  border-collapse: collapse;
  box-shadow: 0 5px 10px #e1e5ee;
  background-color: white;
  text-align: left;
  overflow: hidden;
}

.thead {
  box-shadow: 10px 10px 5px grey;
}

.th {
  text-align: center;
  font-family: "Mukta Malar", sans-serif;
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 19px;
  font-weight: 600;
  /* cursor: pointer; */
}

.td {
  text-align: center;
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 450;
}

.td-product {
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 450;
}

/* .sort{
  margin-left: 2px;
  color: rebeccapurple;
} */
.fechasPicker {
  margin-top: 5px;
}

.clientes {
  width: 50px;
}

.clientes-recurrencia {
  float: left;
  font-size: 30px;
  font-weight: 600;
  margin-top: 13px;
  margin-left: 15px;
}

.download-occurrence {
  margin: 13px 10px 0 10px;
  float: right;
}

#cliente {
  width: 20%;
}

.flex-widgets {
  display: flex;
  justify-content: space-around;
  margin-top: 55px;
}

.widgets-clientes {
  width: 200px;
}

.social {
  margin: 30px;
}

/* HOME */

.widgetHome {
  height: 105px;
}

/*FIN HOME */

/* VENTAS GLOBALES */
.table-first-period {
  height: 500px;
  overflow: scroll;
}
.pad{
  display:flex;
  justify-content: space-around;
}
/* .fixed{
  position: fixed;
  background-color: white;
} */
/* FIN VENTAS GLOBALES */
/* LATEST PERIOD */
.dateRangeStart {
  font-size: 20px;
}

/* FIN LATEST PERIOD */
.title {
  font-size: 25px;
  font-weight: 600;
  margin-top: 13px;
  margin-left: 15px;
  margin-bottom: 20px;
}

.me-2 {
  margin-top: 15px;
}

.toggle {
  display: flex;
  justify-content: center;
}

.successFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.space{
  height: 11px;
}